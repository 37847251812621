import * as Poppins from "@remotion/google-fonts/Poppins";
import * as Rubik from "@remotion/google-fonts/Rubik";
import { preloadVideo } from "@remotion/preload";
import bearing from "@turf/bearing";
import bezierSpline from "@turf/bezier-spline";
import greatCircle from "@turf/great-circle";
import { lineString, point } from "@turf/helpers";
import length from "@turf/length";

import { Colors } from "components/common/Colors";
import StipplLogo from "components/website/img/stippl-logo.svg";
import { first, last, sum } from "lodash";
import mapboxgl, { Map } from "mapbox-gl";
import React, { useEffect, useRef, useState } from "react";
import {
  AbsoluteFill,
  Composition,
  Sequence,
  Series,
  Video,
  continueRender,
  delayRender,
  prefetch,
  staticFile,
} from "remotion";
import { getGeocodes, getItemID } from "utils/getters";
import mapChip from "../../img/markers/map-chip.png";
import "./TripReel.scss";
// import Logo from "./img/goboony_logo.png";
// import UserBadge from "./img/goboony_userbadge.png";
import PoweredBy from "./img/poweredBy.svg";

import Bus from "./icons/bus.png";
import Car from "./icons/car.png";
import Ferry from "./icons/ferry.png";
import Plane from "./icons/plane.png";
import Train from "./icons/train.png";
import "./reeldestinationmarker/ReelDestinationMarker.scss";
import "./reeltitle/ReelTitle.scss";
import IntroSequence from "./sequences/IntroSequence";
import MarkerSequence from "./sequences/MarkerSequence";
import PathSequence from "./sequences/PathSequence";
import PhotoSequence from "./sequences/PhotoSequence";

prefetch("stipplOutro.webm");
preloadVideo("stipplOutro.webm");

const INTRO = 60;
const MARKER_DURATION = 30;
const MAX_MAP_DURATION = 80;
const PHOTO_DURATION = 25;
const OUTRO_DURATION = 110;
const START_ALTITUDE = 250000;
const START_PITCH = 50;

const convertMultiLineStringToLineString = (multiLine) => {
  let points = [];

  // Iterate through each line segment
  multiLine.geometry.coordinates.forEach((line, index) => {
    // Add points from the current line
    line.forEach((point, pointIndex) => {
      // Adjust for antimeridian crossing
      if (index > 0 && pointIndex === 0) {
        let lastPoint = points[points.length - 1];
        let lonDiff = point[0] - lastPoint[0];

        if (Math.abs(lonDiff) > 180) {
          // Adjust longitude by 360 degrees
          point[0] += lonDiff > 0 ? -360 : 360;
        }
      }
      points.push(point);
    });
  });

  // Create a LineString from the points
  return lineString(points);
};

const mapStyles = [
  { label: "Stippl", url: "mapbox://styles/omars/clgar7r6s000401pjx0hrkkeg" },
  { label: "Conservio", url: "mapbox://styles/omars/cl32rf2my006d14pfv7saxzkp" },
  { label: "Stippl Cartoon", value: "stipplDefault", url: "mapbox://styles/omars/cknp25tkb2dbm17p7azbj89pm" },
  { label: "Streets", value: "mapboxStreets", url: "mapbox://styles/mapbox/streets-v11" },
  { label: "Bubbles", value: "mapboxBubbles", url: "mapbox://styles/omars/cl2sscnua001e15oblyn7m8sx" },
  { label: "Terrain", value: "mapboxTerrain", url: "mapbox://styles/omars/cl2ssvth3006514mfr55rpbn7" },
  { label: "Satellite", value: "mapboxSatellite", url: "mapbox://styles/omars/clgni29a200dr01pe1ryt2z0y" },
];

//Styles

// const PRIMARY_COLOR = "#FF4252";
const MAP_STYLE_URL = mapStyles?.[0].url;

const transportIcons = [
  { name: "planeIcon", icon: Plane },
  { name: "carIcon", icon: Car },
  { name: "trainIcon", icon: Train },
  { name: "busIcon", icon: Bus },
  { name: "ferryIcon", icon: Ferry },
];

mapboxgl.accessToken = "pk.eyJ1Ijoib21hcnMiLCJhIjoiY2w0NDN3OHRvMDAxazNkcXRzcG1kMmpyMCJ9.l6IL4HDt1K5dfcDAFnp-vg";

const DEFAULT_STYLES = {
  MARKER_COLOR: Colors.white,
  MARKER_PULSE_COLOR: Colors.main1,
  MARKER_TEXT_COLOR: Colors.main1,
  DESTINATION_COLOR: Colors.main1,
  DESTINATION_TEXT_COLOR: Colors.white,
  TRANSPORT_PATH_COLOR: Colors.main2,
  TRANSPORT_MARKER_COLOR: Colors.main2,
  TRANSPORT_ICON_COLOR: Colors.white,
  MAP_STYLE_URL: mapStyles?.[0],
  PROFILE_PICTURE: "",
  BRAND_LOGO: "",
  showLogo: true,
  showOutro: true,
  showJournalTitle: false,
};

export const CompositionComponent = ({ currentTrip, reelData, styles = DEFAULT_STYLES }) => {
  Poppins.loadFont();
  Rubik.loadFont();

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);

  const [handle] = useState(() => delayRender("Loading map..."));

  const paths = reelData?.map((item) => {
    return item?.path?.length > 0
      ? item?.path?.length === 2
        ? greatCircle(
            point([item?.path?.[0]?.[0], item?.path?.[0]?.[1]]),
            point([item?.path?.[1]?.[0], item?.path?.[1]?.[1]])
          )
        : bezierSpline(lineString(item?.path))
      : null;
  });

  const mapDestinationFeatures = currentTrip?.destinations.map((item, index) => {
    const feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: getGeocodes(item),
      },
      properties: {
        id: getItemID(item),
        index: index,
        city: styles.showJournalTitle
          ? reelData?.[index]?.journalTitle || item?.cityEnglish
          : item?.cityEnglish || item?.normalised_city,
      },
    };
    return feature;
  });

  useEffect(() => {
    const _map = new Map({
      container: "map",
      style: styles.MAP_STYLE_URL.url,
      interactive: true,
      zoom: 0,
      fadeDuration: 0,
      center: [0, 0],
      //@ts-ignore
      projection: "globe",
    });

    _map.loadImage(mapChip, (error, image) => {
      if (error) throw error;
      _map.addImage("mapChip", image, { sdf: true });
    });

    if (styles.PROFILE_PICTURE) {
      _map.loadImage(styles.PROFILE_PICTURE, (error, image) => {
        if (error) throw error;
        _map.addImage("UserBadge", image);
      });
    }

    {
      transportIcons.map((icon) =>
        _map.loadImage(icon.icon, (error, image) => {
          if (error) throw error;
          _map.addImage(icon.name, image, { sdf: true });
        })
      );
    }

    const transportDot = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: paths?.[0].geometry.coordinates?.[0],
      },
      properties: {},
    };

    _map.on("style.load", () => {
      _map.addSource(`transportMarkerSource`, {
        type: "geojson",
        //@ts-ignore
        data: transportDot,
        lineMetrics: true,
      });
      _map.addLayer({
        type: "circle",
        source: `transportMarkerSource`,
        id: `transportMarkerDot`,
        paint: {
          "circle-color": styles.TRANSPORT_MARKER_COLOR,
          "circle-radius": 24,
          "circle-opacity": 0,
        },
      });
      ////////

      _map.addLayer({
        type: "symbol",
        source: `transportMarkerSource`,
        id: `transportMarkerIcon`,
        layout: {
          "icon-image": "",
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-size": 0.875,
        },
        paint: {
          "icon-opacity": 0,
          "icon-color": styles.TRANSPORT_ICON_COLOR,
        },
      });

      ////////

      _map.addLayer({
        type: "symbol",
        source: `transportMarkerSource`,
        id: `transportProfilePicture`,
        layout: {
          "icon-image": "UserBadge",
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "icon-size": 0.75,
        },
        paint: {
          "icon-opacity": 0,
        },
      });
      paths?.map(
        (path, index) => (
          _map.addSource(`source-${index}`, {
            type: "geojson",
            data: path,
            lineMetrics: true,
          }),
          _map.addLayer({
            type: "line",
            source: `source-${index}`,
            id: `line-${index}`,
            paint: {
              "line-color": styles.TRANSPORT_PATH_COLOR,
              "line-width": 6,
              "line-opacity": 0,
            },
          })
        )
      );
      mapDestinationFeatures?.map((marker, index) => {
        _map.addSource(`markerSource-${index}`, {
          type: "geojson",
          data: marker,
          lineMetrics: true,
        });
        //Green marker pulse
        _map.addLayer({
          type: "circle",
          source: `markerSource-${index}`,
          id: `pulse-${index}`,
          paint: {
            "circle-color": styles.MARKER_PULSE_COLOR,
            "circle-radius": 24,
            "circle-opacity": 0,
          },
        });
        //Destination name
        _map.addLayer({
          type: "symbol",
          source: `markerSource-${index}`,
          id: `destinationName-${index}`,
          layout: {
            "text-field": `${marker.properties.city}`,
            "text-font": ["Poppins SemiBold"],
            "text-size": 38,
            "icon-image": "mapChip",
            "icon-text-fit": "both",
            "icon-text-fit-padding": [16, 32, 16, 32],
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": styles.DESTINATION_TEXT_COLOR,
            "icon-color": styles.DESTINATION_COLOR,
            "text-opacity": 0,
            "icon-opacity": 0,
            "icon-translate": [0, 0],
            "text-translate": [0, 0],
            "icon-translate-anchor": "viewport",
            "text-translate-anchor": "viewport",
          },
        });
        //White marker circle
        _map.addLayer({
          type: "circle",
          source: `markerSource-${index}`,
          id: `marker-${index}`,
          paint: {
            "circle-color": styles.MARKER_COLOR,
            "circle-radius": 32,
            "circle-opacity": 0,
          },
        });
        //Number in marker circle
        _map.addLayer({
          type: "symbol",
          source: `markerSource-${index}`,
          id: `countText-${index}`,
          layout: {
            "text-field": `${index + 1}`,
            "text-font": ["Poppins Bold"],
            "text-size": 36,
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": styles.MARKER_TEXT_COLOR,
            "text-opacity": 0,
          },
        });
      });
    });

    _map.on("load", () => {
      continueRender(handle);
      setMap(_map);
    });
  }, [handle, styles.MAP_STYLE_URL, styles.PROFILE_PICTURE]);

  //Update style
  useEffect(() => {
    mapDestinationFeatures?.map((marker, index) => {
      //destination name background color
      map?.setPaintProperty(`destinationName-${index}`, "icon-color", styles.DESTINATION_COLOR);
      //destination name text color
      map?.setPaintProperty(`destinationName-${index}`, "text-color", styles.DESTINATION_TEXT_COLOR);
      //Marker color
      map?.setPaintProperty(`marker-${index}`, "circle-color", styles.MARKER_COLOR);
      //Pulse color
      map?.setPaintProperty(`pulse-${index}`, "circle-color", styles.MARKER_PULSE_COLOR);
      //Marker text color
      map?.setPaintProperty(`countText-${index}`, "text-color", styles.MARKER_TEXT_COLOR);
    });
    //Transport Marker color
    map?.setPaintProperty(`transportMarkerDot`, "circle-color", styles.TRANSPORT_MARKER_COLOR);
    //Transport Icon color
    map?.setPaintProperty(`transportMarkerIcon`, "icon-color", styles.TRANSPORT_ICON_COLOR);
  }, [styles]);

  const renderLogo = () =>
    styles.showLogo ? (
      styles.BRAND_LOGO ? (
        <AbsoluteFill>
          <div className="stippl-logo-container">
            <div className="stippl-logo">
              <img src={styles.BRAND_LOGO} alt="Brand logo" />
            </div>
            <div className="poweredby">
              <img src={PoweredBy} alt="poweredBy" />
            </div>
          </div>
        </AbsoluteFill>
      ) : (
        <AbsoluteFill>
          <div className="stippl-logo-container">
            <div className="stippl-logo">
              <img src={StipplLogo} alt="Brand logo" />
            </div>
          </div>
        </AbsoluteFill>
      )
    ) : null;
  return (
    <>
      <AbsoluteFill ref={ref} id="map" />

      <Sequence from={0} durationInFrames={INTRO}>
        <IntroSequence
          map={map}
          currentTrip={currentTrip}
          INTRO_DURATION={INTRO}
          targetLngLat={{
            lng: currentTrip?.destinations?.[0]?.longitude,
            lat: currentTrip?.destinations?.[0]?.latitude,
          }}
          startAltitude={32000000}
          endAltitude={START_ALTITUDE}
          startBearing={0}
          endBearing={bearing(first(reelData?.[0]?.path), last(reelData?.[0]?.path)) / 3}
          startPitch={0}
          endPitch={START_PITCH}
        />
      </Sequence>
      <Sequence from={INTRO} durationInFrames={MARKER_DURATION}>
        <MarkerSequence
          index={0}
          map={map}
          MARKER_DURATION={MARKER_DURATION}
          reelData={reelData}
          currentTrip={currentTrip}
          startAltitude={START_ALTITUDE}
          startPitch={START_PITCH}
        />
      </Sequence>

      {reelData[0].photos?.length > 0 ? (
        <Sequence from={INTRO + MARKER_DURATION} durationInFrames={PHOTO_DURATION * reelData[0].photos.length}>
          <PhotoSequence
            photos={reelData[0]?.photos}
            city={reelData[0]?.city}
            nights={reelData[0]?.duration}
            journalTitle={reelData[0]?.journalTitle}
            PHOTO_DURATION={PHOTO_DURATION}
            showJournalTitle={styles.showJournalTitle}
          />
        </Sequence>
      ) : null}
      {renderLogo()}
      <Sequence from={INTRO + MARKER_DURATION + PHOTO_DURATION * reelData[0].photos?.length}>
        <Series>
          {reelData.map((destination: any, index) => {
            let path: any =
              destination?.path?.length > 0
                ? destination?.path?.length === 2
                  ? greatCircle(
                      point([destination?.path?.[0]?.[0], destination?.path?.[0]?.[1]]),
                      point([destination?.path?.[1]?.[0], destination?.path?.[1]?.[1]])
                    )
                  : bezierSpline(lineString(destination?.path))
                : [];

            if (path?.geometry?.type === "MultiLineString") {
              path = convertMultiLineStringToLineString(path);
            }
            //@ts-ignore
            const routeDistance = !!path?.geometry ? length(path) : 0;

            //Maybe needs some work to smooth zoom on transition between altitudes
            const altitude = routeDistance < 100 ? 50000 : START_ALTITUDE;

            const pathDuration =
              Math.round(
                Math.min(Math.max(Math.floor(routeDistance * 0.08), MAX_MAP_DURATION / 2.25), MAX_MAP_DURATION)
              ) || 0;

            return destination?.path?.length > 0 ? (
              <React.Fragment key={`${destination?.city}-${index}`}>
                {!!pathDuration ? (
                  <Series.Sequence durationInFrames={pathDuration} key={`path-${destination?.city}-${index}`}>
                    <PathSequence
                      map={map}
                      MAP_DURATION={isNaN(pathDuration) ? 0 : pathDuration}
                      reelData={reelData}
                      path={path}
                      index={index}
                      startAltitude={altitude}
                      startPitch={START_PITCH}
                      smooth={destination?.path?.length > 2}
                      PATH_COLOR={styles.TRANSPORT_PATH_COLOR}
                    />
                  </Series.Sequence>
                ) : null}
                <Series.Sequence durationInFrames={MARKER_DURATION} key={`marker-${destination?.city}-${index}`}>
                  <MarkerSequence
                    index={index + 1}
                    map={map}
                    MARKER_DURATION={MARKER_DURATION}
                    reelData={reelData}
                    currentTrip={currentTrip}
                    startAltitude={altitude}
                    startPitch={START_PITCH}
                  />
                </Series.Sequence>
                {reelData?.[index + 1]?.photos?.length > 0 ? (
                  <Series.Sequence
                    durationInFrames={PHOTO_DURATION * reelData?.[index + 1]?.photos?.length}
                    key={`photo-${destination?.city}-${index}`}
                  >
                    <PhotoSequence
                      photos={reelData[index + 1].photos}
                      city={reelData[index + 1]?.city}
                      nights={reelData[index + 1]?.duration}
                      PHOTO_DURATION={PHOTO_DURATION}
                      journalTitle={reelData[index + 1]?.journalTitle}
                      showJournalTitle={styles.showJournalTitle}
                    />
                    {renderLogo()}
                  </Series.Sequence>
                ) : null}
              </React.Fragment>
            ) : null;
          })}

          <Series.Sequence durationInFrames={OUTRO_DURATION + 120}>
            <AbsoluteFill>
              <Video src={staticFile("stipplOutro.webm")} />
            </AbsoluteFill>
          </Series.Sequence>
        </Series>
      </Sequence>
    </>
  );
};

const calculateMetaData = ({ props }) => {
  const { reelData, styles } = props;
  const amountOfPhotos = reelData?.flatMap((item) => item.photos)?.length;
  const mapDurations = reelData.map((destination) => {
    const path =
      destination?.path?.length > 0
        ? destination?.path?.length === 2
          ? greatCircle(
              point([destination?.path?.[0]?.[0], destination?.path?.[0]?.[1]]),
              point([destination?.path?.[1]?.[0], destination?.path?.[1]?.[1]])
            )
          : bezierSpline(lineString(destination?.path))
        : [];
    //@ts-ignore
    const routeDistance = path?.geometry ? length(path) : 0;
    const pathDuration = Math.round(
      Math.min(Math.max(Math.floor(routeDistance * 0.08), MAX_MAP_DURATION / 2.25), MAX_MAP_DURATION)
    );
    return isNaN(pathDuration) ? 0 : pathDuration;
  });

  const OUTRO = styles?.showOutro ? OUTRO_DURATION : -10;
  const durationInFrames =
    sum(mapDurations) + MARKER_DURATION * (reelData?.length - 1) + PHOTO_DURATION * amountOfPhotos + INTRO + OUTRO;

  return {
    durationInFrames,
    props,
  };
};

const TikTokVideo: React.FC = () => {
  return (
    <Composition
      //@ts-ignore
      component={CompositionComponent}
      fps={30}
      durationInFrames={1200}
      id="reel"
      width={1080}
      height={1920}
      defaultProps={{ currentTrip: {}, reelData: [], PRIMARY_COLOR: "", styles: DEFAULT_STYLES }}
      calculateMetadata={calculateMetaData}
    />
  );
};

export default TikTokVideo;
