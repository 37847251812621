import Icons from "./Icons.json";

interface IconProps {
  name: keyof typeof Icons;
  fill?: string;
  viewBox?: string;
  classname?: string;
}

const Icon: React.FC<IconProps> = ({ name, classname = "", fill = "", viewBox = "0 0 1024 1024" }) => (
  //@ts-ignore
  <svg viewBox={Icons[name]?.viewBox || viewBox} className={classname} fill={fill}>
    {Icons[name]?.path?.map((path) => (
      <path key={path} d={path} fill={fill}></path>
    ))}
  </svg>
  // <svg viewBox={viewBox} className={classname} fill={fill}>
  //   {icons[name]?.map((path) => (
  //     <path key={path} d={path}></path>
  //   ))}
  // </svg>
);

export default Icon;
