import Icon from "components/Icons/Icon";
import Icons from "components/Icons/Icons.json";
import "./TextTag.scss";

interface TextTagProps {
  text: string;
  icon?: keyof typeof Icons;
  color?: "black" | "green" | "orange" | "magenta" | "purple";
  flag?: boolean;
  tooltip?: any;
}

const TextTag: React.FC<TextTagProps> = ({ text, icon, color = "black", flag = false, tooltip }) => {
  return text ? (
    <div className={`card-tag ${color} ${flag ? "flag" : ""}`} data-tip={tooltip}>
      {icon ? (
        <div className="icon">
          <Icon name={icon} />
        </div>
      ) : null}
      {text}
    </div>
  ) : null;
};

export default TextTag;
