import countryCodes from "components/dashboard/countryISOCodes.json";
import { find } from "lodash";
import ReactCountryFlag from "react-country-flag";

const Flag = ({ country }) => {
  const { alpha2 } = find(countryCodes, (countryFromList) => countryFromList.country === country) ?? {};
  return (
    <div className="flag">
      <ReactCountryFlag svg countryCode={alpha2} />
    </div>
  );
};

export default Flag;
