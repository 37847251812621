import TextTag from "components/cards/TextTag";
import { AbsoluteFill, Easing, Img, Series, interpolate, staticFile, useCurrentFrame } from "remotion";
const PhotoSequence = ({ photos, city, nights, PHOTO_DURATION, journalTitle, showJournalTitle }) => {
  const photoFrame = useCurrentFrame();

  const fade = interpolate(photoFrame, [0, 10], [0, 1], {
    extrapolateRight: "clamp",
  });

  const bounce = interpolate(photoFrame, [0, 12], [-100, 0], {
    easing: Easing.elastic(1.2),
    extrapolateRight: "clamp",
  });

  const onImageError = (e) => {
    e.preventDefault();
    e.target.onerror = null; // prevents looping
    e.target.src = staticFile("image-placeholder.png");
  };

  const nightsLabel = nights === 0 ? "Stopover" : `${nights} night${nights > 1 ? "s" : ""}`;
  return (
    <Series>
      {photos?.map((photo) => (
        <Series.Sequence durationInFrames={PHOTO_DURATION} key={photo}>
          <AbsoluteFill>
            <div className="reel-title top-align" style={{ opacity: fade, transform: `translateY(${bounce}px)` }}>
              <div className="badge-container">
                <TextTag icon={nights === 0 ? undefined : "moon--outlined"} text={nightsLabel} color="black" />
              </div>
              <h1>
                <span>{showJournalTitle ? journalTitle || city : city}</span>
              </h1>
            </div>
          </AbsoluteFill>
          <AbsoluteFill className="photo-container">
            <Img
              referrerPolicy="no-referrer"
              src={`https://media.stippl.io/${photo}`}
              alt="tripImage"
              key={photo}
              onError={onImageError}
            />
          </AbsoluteFill>
        </Series.Sequence>
      ))}
    </Series>
  );
};

export default PhotoSequence;
