let previousCameraPosition;

// amazingly simple, via https://codepen.io/ma77os/pen/OJPVrP
function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end;
}

export const computeCameraPosition = (pitch, bearing, targetPosition, altitude, smooth = false) => {
  var bearingInRadian = bearing / 57.29;
  var pitchInRadian = (90 - pitch) / 57.29;

  const c = 24902.0; // earth circumference in miles
  const d = ((Math.cos((targetPosition?.lat * Math.PI) / 180.0) * c) / 360.0) * 1.609344;

  var lngDiff = ((altitude / Math.tan(pitchInRadian)) * Math.sin(-bearingInRadian)) / (d * 1000);
  var latDiff = ((altitude / Math.tan(pitchInRadian)) * Math.cos(-bearingInRadian)) / 111111; // 110km/degree latitude

  var correctedLng = targetPosition?.lng + lngDiff;
  var correctedLat = targetPosition?.lat - latDiff;

  const newCameraPosition = {
    lng: correctedLng,
    lat: correctedLat,
  };

  if (smooth) {
    if (previousCameraPosition) {
      const SMOOTH_FACTOR = 0.6;
      newCameraPosition.lng = lerp(newCameraPosition.lng, previousCameraPosition.lng, SMOOTH_FACTOR);
      newCameraPosition.lat = lerp(newCameraPosition.lat, previousCameraPosition.lat, SMOOTH_FACTOR);
    }
  }

  previousCameraPosition = newCameraPosition;

  return newCameraPosition;
};
